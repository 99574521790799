





































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { endOfDay, startOfDay } from 'date-fns';
import { DEVICE_DEFAULT } from '@/models/device/defaults';
import { DATA_APPLICATION_DEFAULT } from '@/models/data/defaults';
import { Device } from '@/models/device/models';
import {
  DataApplication,
  DataSourceOutputType,
  DataSourceTemplate,
  DeviceRelation,
} from '@/models/data/models';
import { Context } from '@/api/ApiClientV2';
import { deepCopy } from '@/util/util';
import DataSourceHandler from './explorer/DataSourceHandler.vue';
import DeviceRelationSelectList from '@/apps/data/components/DeviceRelationSelectList.vue';

interface TemplateSelection {
  selected: boolean;
  name: string;
  id: string;
}

@Component({
  components: {
    DataSourceHandler,
    DeviceRelationSelectList,
  },
})
export default class DataExplorer extends Vue {
  $refs: {
    dataSourceHandler: DataSourceHandler;
  };
  templateSelection: TemplateSelection[] = [];
  numSelectedTemplates = 0;
  application: DataApplication = deepCopy(DATA_APPLICATION_DEFAULT);
  device: Device = deepCopy(DEVICE_DEFAULT);
  errorMessages: string[] = [];
  loading = true;
  startDate = new Date();
  currentRole = '';

  @Watch('$route.query')
  onRouteChanged() {
    this.load();
  }

  mounted() {
    this.load();
  }

  get appId(): string {
    return this.$store.getters['global/application']
      ? this.$store.getters['global/application'].id
      : 'unknown'; // explicitly set to 'unknown' to not show anything
  }

  handleError(error) {
    this.loading = false;
    this.errorMessages = this.$errorHandler.errorToStrings(error);
  }

  async load() {
    this.errorMessages = [];
    this.loading = true;
    try {
      const appId = this.$store.getters['global/application'].id;
      if (!appId) {
        this.loading = false;
        return;
      }
      this.application = await this.$apiv2.get<DataApplication>(
        DataApplication,
        appId,
      );
      const deviceRelationId = this.$routerHandler.query('')['device_relation'];
      if (!deviceRelationId) {
        this.loading = false;
        return;
      }
      const deviceRelation = await this.$apiv2.get<DeviceRelation>(
        DeviceRelation,
        deviceRelationId,
      );
      if (!deviceRelation) {
        this.loading = false;
        return;
      }
      if (this.currentRole && deviceRelation.role !== this.currentRole) {
        this.clearTemplateSelection();
      }
      this.currentRole = deviceRelation.role;
      this.device = await this.$apiv2.get<Device>(
        Device,
        deviceRelation.device,
      );

      const context: Context = {
        filter: {
          application: this.application.id,
          plugin_id: 'device-data-event-log-exporter',
        },
        pagination: {
          page: 1,
          pageSize: 200,
        },
      };
      const templates = await this.$apiv2.getListItems<DataSourceTemplate>(
        DataSourceTemplate,
        context,
      );
      const templatesQuery = this.$routerHandler.query('')['templates'];
      this.templateSelection = templates.map(template => {
        return {
          selected: !!templatesQuery && templatesQuery.includes(template.id),
          name: template.handle,
          id: template.id,
        };
      });
      // getting date from route has to happen before addTemplate call
      const dateString = this.$routerHandler.query('')['date'];
      if (dateString) {
        this.startDate = new Date(dateString);
      }
      this.numSelectedTemplates = 0;
      this.templateSelection.forEach(template => {
        if (template.selected) {
          this.addTemplate(template);
          this.numSelectedTemplates += 1;
        }
      });
    } catch (error) {
      this.clearTemplateSelection();
      this.handleError(error);
    }
    this.loading = false;
  }

  clearTemplateSelection() {
    this.$routerHandler.updateQuery('', { templates: '' });
    this.$refs.dataSourceHandler.removeAllDataSourceTemplate();
    this.templateSelection = [];
    this.numSelectedTemplates = 0;
  }

  selectionChanged(id: string, selected: boolean) {
    if (selected) {
      this.templateSelection.forEach(template => {
        if (template.id === id) {
          this.addTemplate(template);
        }
      });
    } else {
      this.removeTemplate(id);
    }

    let num = 0;
    this.templateSelection.forEach(template => {
      if (template.selected) {
        num += 1;
      }
    });
    this.numSelectedTemplates = num;
  }

  addTemplate(template: TemplateSelection) {
    let templatesQuery = this.$routerHandler.query('')['templates'];
    if (!templatesQuery) {
      templatesQuery = template.id;
    } else if (!templatesQuery.includes(template.id)) {
      templatesQuery = `${templatesQuery},${template.id}`;
    }
    this.$routerHandler.updateQuery('', {
      templates: templatesQuery,
    });
    this.$refs.dataSourceHandler.addDataSourceTemplate({
      template: template.id,
      parameters: this.parameters,
      output_type: DataSourceOutputType.TIME_SERIES,
    });
  }

  removeTemplate(id: string) {
    const templatesQuery = this.$routerHandler.query('')['templates'];
    this.$routerHandler.updateQuery('', {
      templates: templatesQuery.replace(id, '').replace(',,', ','),
    });
    this.$refs.dataSourceHandler.removeDataSourceTemplate(id);
  }

  get parameters() {
    return {
      start_time: startOfDay(this.startDate).toISOString(),
      end_time: endOfDay(this.startDate).toISOString(),
      device: this.device.id,
    };
  }

  paramsChanged() {
    this.$routerHandler.updateQuery('', { date: this.startDate.toISOString() });
    // Prepare data source request
    this.$refs.dataSourceHandler.setTemplateParameters(this.parameters);
  }
}
